import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import LeftIcon from '../../../assets/images/left-arw.png';
import RightIcon from '../../../assets/images/right-arw.png';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactSelect from 'react-select';
import {
  useCategoriesSellerServices,
  useCreateAppointment,
  useGetAvailableAppointmentSlots,
  useGetBuyerContacts,
  useGetWeeklySchedule,
} from '../../../hooks';
import { format } from 'date-fns';
import { appointmentBookingSchema } from '../../../constants/book-appointment-validation';
import { showError, showSuccess } from '../../../utils';
import { Checkbox, Spinner } from '../../atoms';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function convertToAbbreviation(fullDayName) {
  // Define an array mapping full day names to their abbreviated forms
  var daysMapping = {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
  };

  // Return the abbreviated form of the day if it exists in the mapping, otherwise return the input
  return daysMapping[fullDayName] || fullDayName;
}

// Define the order of days of the week
const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const sortArrayByDayName = (data) => {
  // Create a mapping of dayName to its index in the daysOfWeek array
  const dayIndexMap = {};
  daysOfWeek.forEach((day, index) => {
    dayIndexMap[day] = index;
  });

  // Sort the array using a custom comparator function
  data.sort((a, b) => {
    const dayIndexA = dayIndexMap[a.shiftDayName?.toLowerCase()];
    const dayIndexB = dayIndexMap[b.shiftDayName?.toLowerCase()];
    return dayIndexA - dayIndexB;
  });

  return data;
};

const formatDateOnly = (value) => {
  const date = new Date(value);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const getCurrentWeekCount = (date) => {
  const now = date || new Date();
  const yearStart = new Date(now.getFullYear(), 0, 0);
  const diff = now - yearStart;
  const oneWeek = 7 * 24 * 60 * 60 * 1000;
  const weekCount = Math.floor(diff / oneWeek);
  return weekCount;
};

const displayDate = (dateString) => {
  return moment(dateString).format('MMMM D, YYYY');
};

const BookAppointmentForm = ({
  setPage,
  selectedClient,
  closeAppontmentModal,
  setIsLoading,
  refetch,
}) => {
  const ref = useRef(null);
  const storedData = sessionStorage.getItem('storefrontData');
  const parsedData = storedData ? JSON.parse(storedData) : null;
  const subscriptionExpiryDate = new Date(parsedData?.subscriptionExpiryDate);
  const futureBookingsEnabled = parsedData?.futureBookingsEnabled;

  const [filter, setFilter] = useState({
    dateRange: {
      fromDate: moment().startOf('month').format('YYYY-MM-DD'),
      toDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
  });
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const [scheduleData, setScheduleData] = useState([]);
  const { data: weeklyData, loading } = useGetWeeklySchedule(filter);
  const currentWeekNumber = getCurrentWeekCount();
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [currentWeek, setCurrentWeek] = useState(currentWeekNumber + 1);
  const [availabelSlotsOption, setAvailabelSlotsOption] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [selectedServiceUid, setSelectedServiceUid] = useState('');
  const [selectedBuyerContactUid, setSelectedBuyerContactUid] = useState('');
  const [selectedBuyerContactName, setSelectedBuyerContactName] = useState('');
  const [buyerContactList, setBuyerContactList] = useState();

  const location = useLocation();
  const buyerUid = location.state;

  const {
    loading: contactLoading,
    data: buyerData,
    refetch: contactRefetch,
  } = useGetBuyerContacts(selectedClient?.uid || buyerUid);

  useEffect(() => {
    if (buyerData?.buyerFamilyMembers) {
      setBuyerContactList(buyerData?.buyerFamilyMembers);
    }
  }, [buyerData]);

  useEffect(() => {
    contactRefetch();
  }, [selectedClient?.uid, buyerUid, contactRefetch]);

  const { data: slotsData, refetch: refetchSlots } =
    useGetAvailableAppointmentSlots(
      {
        sellerServiceUid: selectedServiceUid,
        shiftDate: selectedDate,
        appointmentUid: '',
      },
      {
        enabled: !!selectedServiceUid, // Enable fetching only if selectedServiceUid is truthy
      }
    );

  const { data: sellerServices } = useCategoriesSellerServices();

  const handleCreateAppointment = useCreateAppointment();

  useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus(), // Expose a focus function
  }));

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(appointmentBookingSchema(buyerContactList)), // Use yupResolver with your schema
    defaultValues: {
      service: '',
      time: '',
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const input = {
      startTime: data.time,
      notifyEmail: data?.emailNotify || false,
      notifySms: data?.mobileNotify || false,
    };
    const sellerServiceUid = selectedServiceUid;
    const buyerContactUid = selectedClient?.uid || buyerUid;
    const familyMemberUid =
      buyerContactList?.length > 1
        ? selectedBuyerContactUid
        : buyerContactList[0]?.uid;
    const note = data?.note;
    try {
      const response = await handleCreateAppointment(
        input,
        sellerServiceUid,
        buyerContactUid,
        familyMemberUid,
        note
      );
      if (
        response?.data?.createAppointment &&
        response?.data?.createAppointment?.status === 'OK'
      ) {
        setPage?.(1);
        closeAppontmentModal();
        refetch();
        reset();
        showSuccess(
          `Appointment has been booked for ${
            selectedBuyerContactName || buyerContactList[0]?.formalName
          } Successfully`
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showError(response);
        console.log('Appointment creation failed');
      }
    } catch (error) {
      console.error('Appointment creation error:', error);
    }
  };

  // Function to handle clicking on the left arrow
  const goToPreviousWeek = () => {
    if (currentWeek > 1) {
      setCurrentWeek(currentWeek - 1);
    }
  };

  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const expiryWeekCount = Math.ceil(
    (subscriptionExpiryDate - startOfYear) / (7 * 24 * 60 * 60 * 1000)
  );
  // Function to handle clicking on the right arrow
  // const goToNextWeek = () => {
  //   if (futureBookingsEnabled) {
  //     setCurrentWeek(currentWeek + 1);
  //   } else if (currentWeek < expiryWeekCount) {
  //     setCurrentWeek(currentWeek + 1);
  //   }
  // };

  const currentDte = new Date();
  const ninetyDaysLater = new Date();
  ninetyDaysLater.setDate(currentDte.getDate() + 90);

  // Function to handle clicking on the right arrow
  const goToNextWeek = () => {
    if (futureBookingsEnabled) {
      setCurrentWeek(currentWeek + 1);
    } else if (
      currentWeek < expiryWeekCount ||
      currentWeek < getCurrentWeekCount(ninetyDaysLater)
    ) {
      setCurrentWeek(currentWeek + 1);
    }
  };

  useEffect(() => {
    if (currentWeek) {
      // Calculate the start date of the given week
      const startDate = moment(`${currentYear}-01-01`)
        .add(currentWeek - 1, 'weeks')
        .startOf('week')
        .add(1, 'days');

      // Calculate the end date of the week
      const endDate = startDate.clone().endOf('week').add(1, 'days');

      setFilter({
        dateRange: {
          fromDate: startDate.format('YYYY-MM-DD'),
          toDate: endDate.format('YYYY-MM-DD'),
        },
      });
    }
  }, [currentWeek]);

  useEffect(() => {
    if (weeklyData) {
      const sortedData = sortArrayByDayName(weeklyData?.sellerScheduleStatuses);
      const currentDate = new Date();
      const ninetyDaysLater = new Date();
      ninetyDaysLater.setDate(currentDate.getDate() + 90); // Set the date to 90 days later

      setScheduleData(
        sortedData.map((item) => {
          const shiftDate = new Date(item.shiftDate);
          const newItem = { ...item };
          if (!futureBookingsEnabled) {
            newItem.enabled =
              shiftDate <= subscriptionExpiryDate &&
              shiftDate <= ninetyDaysLater;
          } else {
            newItem.enabled = shiftDate <= ninetyDaysLater;
          }
          return newItem;
        })
      );
    }
  }, [weeklyData, futureBookingsEnabled]);

  useEffect(() => {
    if (sellerServices) {
      setServicesData(sellerServices?.categoriesSellerServices);
    }
  }, [sellerServices]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (selectedDate !== null) {
      refetchSlots();
    }
  }, [selectedDate, refetchSlots]);

  const isCurrentWeekInSchedule = () => {
    // Logic to check if currentDate falls within the currentWeek range
    const startDate = new Date(`${moment().year()}-01-01`);
    startDate.setDate(startDate.getDate() + (currentWeek - 1) * 7);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd');
    return (
      formattedCurrentDate >= formatDateOnly(startDate) &&
      formattedCurrentDate <= formatDateOnly(endDate)
    );
  };

  useEffect(() => {
    const isCurrentDateEnabled = scheduleData.some(
      (item) => item.shiftDate === currentDate && item.enabled
    );

    if (isCurrentDateEnabled) {
      setSelectedDate(currentDate);
    } else {
      const nextEnabledDate = scheduleData.find(
        (item) => item.shiftDate > currentDate && item.enabled
      );
      if (nextEnabledDate) {
        setSelectedDate(nextEnabledDate.shiftDate);
      }
    }
  }, [currentDate, scheduleData]);

  const customSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={10}
        style={{ width: '100%', maxWidth: '100%', padding: '2px 8px' }}
      /> // Set the input style
    );
  };

  useEffect(() => {
    if (slotsData?.availableAppointmentSlots?.avaliableTimeSlots) {
      const currentTime = new Date(); // Get the current time
      const ninetyDaysLater = new Date();
      ninetyDaysLater.setDate(currentTime.getDate() + 90); // Set the date to 90 days later
      const options = slotsData.availableAppointmentSlots.avaliableTimeSlots
        .filter((slot) => {
          const slotDate = new Date(slot);
          return slotDate > currentTime && slotDate <= ninetyDaysLater;
        })
        .map((slot) => ({
          value: slot,
          label: new Date(slot).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Use 12-hour format with AM/PM
          }),
        }));

      setAvailabelSlotsOption(options);
    }
  }, [slotsData, selectedDate]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      whiteSpace: 'pre-wrap', // Allows text to wrap
      wordBreak: 'break-word',
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: 'bold', // Make the group heading bold
      color: '#000',
    }),
  };

  const customServiceSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={100}
        style={{ width: '100%', maxWidth: '100%', padding: '2px 8px' }}
      /> // Set the input style
    );
  };

  return (
    <>
      {(contactLoading || loading) && <Spinner />}
      <form className="book-appointment" onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4">
          {buyerContactList && buyerContactList?.length > 1 ? (
            <>
              <Controller
                name="client"
                control={control}
                defaultValue={
                  buyerContactList?.length > 1
                    ? selectedBuyerContactUid
                    : buyerContactList[0]?.uid
                } // Set the default value to the selectedServiceUid state
                render={({ field }) => (
                  <ReactSelect
                    className="react-select-container text-sm w-full"
                    classNamePrefix="react-select"
                    options={buyerContactList.map((contact) => ({
                      value: contact.uid,
                      label: contact.formalName,
                    }))}
                    placeholder="Select Client"
                    styles={customStyles}
                    onChange={(option) => {
                      setSelectedBuyerContactUid(option.value); // Update the selectedServiceUid state
                      setSelectedBuyerContactName(option.label);
                      field.onChange(option.value); // Trigger the field's onChange event for react-hook-form
                    }}
                  />
                )}
              />
              {errors && errors?.client && (
                <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
                  {errors?.client?.message}
                </p>
              )}
            </>
          ) : (
            <>
              <h3 className="capitalize">
                <label className="text-sm  text-gray-dark">Client Name:</label>{' '}
                {buyerContactList && buyerContactList[0]?.formalName}
              </h3>
              <input
                type="hidden"
                name="client"
                value={buyerContactList && buyerContactList[0]?.uid}
              />
            </>
          )}
        </div>

        <div className="my-4">
          <Controller
            name="service"
            control={control}
            defaultValue={selectedServiceUid} // Set the default value to the selectedServiceUid state
            render={({ field }) => (
              <ReactSelect
                className="react-select-container text-sm w-full"
                classNamePrefix="react-select"
                options={servicesData.map((category) => ({
                  label: category.name,
                  options: category.sellerServices.map((service) => ({
                    value: service.uid,
                    label: service.name,
                  })),
                }))}
                placeholder="Select Service"
                styles={customStyles}
                onChange={(selectedOption) => {
                  setSelectedServiceUid(selectedOption.value); // Update the selectedServiceUid state
                  field.onChange(selectedOption.value); // Trigger the field's onChange event for react-hook-form
                }}
                components={{ Input: customServiceSelectSearchInput }} // Custom input component
              />
            )}
          />
          {errors && errors?.service && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.service?.message}
            </p>
          )}
        </div>
        <div className="my-4">
          <div className="calander-box mt-8 md:w-full md:block">
            <div className="flex gap-4 justify-between items-center w-full">
              <p className="md:text-base text-xs font-semibold">
                {selectedDate
                  ? displayDate(selectedDate)
                  : 'Please Select Date'}
              </p>
              <div className="flex gap-4">
                <img
                  src={LeftIcon}
                  alt="icon"
                  className=" cursor-pointer"
                  style={{ opacity: isCurrentWeekInSchedule() && 0.3 }}
                  onClick={() => {
                    if (!isCurrentWeekInSchedule()) {
                      goToPreviousWeek();
                    }
                  }}
                />
                {/* <img
                  src={RightIcon}
                  alt="icon"
                  className={`cursor-pointer ${
                    futureBookingsEnabled
                      ? ''
                      : currentWeek >= expiryWeekCount
                      ? 'opacity-40 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={() => {
                    if (futureBookingsEnabled) {
                      goToNextWeek();
                    } else {
                      if (currentWeek >= expiryWeekCount) {
                        return;
                      } else {
                        goToNextWeek();
                      }
                    }
                  }}
                /> */}
                <img
                  src={RightIcon}
                  alt="icon"
                  className={`cursor-pointer ${
                    futureBookingsEnabled &&
                    currentWeek - 1 < getCurrentWeekCount(ninetyDaysLater)
                      ? ''
                      : currentWeek >= expiryWeekCount
                      ? 'opacity-40 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      futureBookingsEnabled &&
                      currentWeek - 1 < getCurrentWeekCount(ninetyDaysLater)
                    ) {
                      goToNextWeek();
                    } else {
                      if (
                        currentWeek >= expiryWeekCount ||
                        currentWeek >= getCurrentWeekCount(ninetyDaysLater)
                      ) {
                        return; // Prevent navigation beyond expiryWeekCount or 90 days
                      } else {
                        goToNextWeek();
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div className="calendar-date flex md:gap-3 gap-1 flex-wrap items-center mt-3">
              {scheduleData?.map((item, index) => {
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const isFutureDate = item.shiftDate >= currentDate;
                return (
                  <div
                    key={index}
                    onClick={() =>
                      isFutureDate &&
                      item?.enabled &&
                      setSelectedDate(item?.shiftDate)
                    }
                    className={`grid border border-purple-bdr rounded-xl md:p-2 p-1 py-2 text-center md:w-45 w-9 ${
                      selectedDate === item?.shiftDate && item?.enabled
                        ? 'border-blue-bar bg-blue-calBg cursor-pointer'
                        : !item?.enabled || !isFutureDate
                        ? 'opacity-40 cursor-not-allowed'
                        : 'hover:border-blue-bar hover:bg-blue-calBg cursor-pointer'
                    }`}
                  >
                    <p className="text-purple-light text-xs">
                      {convertToAbbreviation(item?.shiftDayName)}
                    </p>
                    <p className="text-black-main font-semibold md:text-base text-sm">
                      {item?.shiftDate?.split('-')[2]}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="my-4">
          <label className="text-sm  text-gray-dark">Time:</label>
          <Controller
            name="time"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <ReactSelect
                className="react-select-container text-sm w-full"
                classNamePrefix="react-select"
                options={
                  availabelSlotsOption?.length
                    ? availabelSlotsOption
                    : [
                        {
                          value: '',
                          label: 'We are closed for today',
                          isDisabled: true,
                        },
                      ]
                }
                placeholder="Time"
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: '12px',
                  }),
                }}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption.value);
                }}
                components={{ Input: customSelectSearchInput }} // Custom input component
                isDisabled={!selectedServiceUid}
              />
            )}
          />
          {errors && errors?.time && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.time?.message}
            </p>
          )}
        </div>
        <div className="md:my-4 my-2">
          <label className="text-sm  text-gray-dark">Note:</label>
          <Controller
            name="note"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <textarea
                {...field}
                className="w-full text-xs font-medium rounded border border-gray-light console-input p-2 my-3"
                rows="4"
                cols="50"
              />
            )}
          />
          {errors && errors?.time && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.time?.message}
            </p>
          )}
        </div>
        <div className="md:mb-4 mb-2 md:w-404 w-auto">
          <label className="md:text-sm text-xs text-black-main font-semibold">
            Please select your preferred notification method (one option
            required) <span className="text-red-drk">*</span>
          </label>
          <div className="flex gap-4">
            <Controller
              name="emailNotify"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label={'Email'}
                  name={field?.name}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />

            <Controller
              name="mobileNotify"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label={'SMS'}
                  name={field?.name}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </div>
          {(errors.emailNotify || errors.mobileNotify) && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625">
              {errors.emailNotify.message || errors.mobileNotify.message}
            </p>
          )}
          <p>
            By selecting one or both options above, you agree to our{' '}
            <a
              href="https://salonhq.co/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="md:text-sm text-xs text-blue-deep font-semibold underline"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://salonhq.co/terms-of-service-sms/"
              target="_blank"
              rel="noreferrer"
              className="md:text-sm text-xs text-blue-deep font-semibold underline"
            >
              SMS Policy
            </a>
            .
          </p>
        </div>
        <button className="h-10 w-full border mt-4 bg-primary text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md">
          Book an appointment
        </button>
      </form>
    </>
  );
};

export default BookAppointmentForm;
